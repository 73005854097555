@import 'variables';
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,600;0,700;0,800;1,400&display=swap');

body {
  background-color: #f0f0f0;
  overflow: auto !important;
  padding: 0 !important;
  font-family: 'Nunito', sans-serif;
}

#root {
  margin-top: 50px;
}

main {
  padding: 35px;
  transition: margin-left .4s;
  margin-left: 60px;
  position: relative;
  transition: .4s;
  padding-left: 30px;
  @media (max-width: $screen-md-max) {
    left: 0;
    &[data-collapsed="false"] {
      padding-left: 0 !important;
      left: 200px;
      @media (min-width: $screen-sm-max) {
        margin-right: 220px;
        left: 220px;
      }
    }
  }
  &[data-is-tour-started="true"] {
    pointer-events: none;
  }
  &[data-collapsed="false"] {
    padding-left: 220px;
    .mobileFilters {
      left: 250px;
    }
  }
  @media (max-width: $screen-sm-max) {
    padding: 50px 10px 20px 0;
    
  }
}

h2 {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 0px;
}

input {
  box-shadow: none !important;
}

strong {
  font-weight: 700;
}

.browserWarning {
  background-color: $green;
  border-radius: 4px;
  line-height: 18px;
  padding: 15px 15px 15px 50px;
  background-image: url('../img/i-information.svg');
  background-size: 20px;
  background-position: left 15px center;
  background-repeat: no-repeat;
  font-size: 14px;
  margin-bottom: 20px;
  a {
    font-weight: 600;
    text-decoration: underline;
    color: black;
  }
}

.view-toggle {
  .icon {
    width: 30px;
    height: 30px;
    background-size: 60px;
    background-position: left center;
    background-repeat: no-repeat;
    margin-right: 5px;
    cursor: pointer;
    border-radius: 3px;
    &.grid {
      background-image: url('../img/i-grid.svg');
    }
    &.list {
      background-image: url('../img/i-list.svg');
    }
    &.active {
      background-position: right center;
    }
  }
}

.flipper {
  background-color: transparent;
  perspective: 1000px;

  .inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform .4s;
    transform-style: preserve-3d;
  }

  &:hover .inner {
    transform: rotateY(180deg);
  }
  .front, .back {
    position: absolute;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-color: #f0f0f0;
  }

  .back {
    transform: rotateY(180deg);
  }
}

.saveToggle {
  cursor: pointer;
  height: 25px;
  width: 60px;
  display: inline-block;
  margin-bottom: -2px;
  margin-left: 15px;
  animation: fadeInUp .4s;
  transform: scale(1);
  opacity: 1;
  transition: .4s;
  &.isUnsaved {
    transform: scale(0);
    opacity: 0;
  }
  .button {
    position: absolute;
    margin-left: -50px;
    transition: margin-left .4s;
  }
  &:hover {
    .icon {
      margin-left: 0px;
    }
    .button {
      margin-left: 5px;
    }
  }
  .flipper {
    height: 25px;
    .back {
      margin-left: 70px;
      margin-top: -3px;
      .icon {
        transform: scaleX(-1);
        margin-top: -1px;
      }
    }
    .front, .back {
      background-color: #f0f0f0;
    }
    .icon {
      margin-left: 1px;
      display: inline-block;
      width: 20px;
      height: 20px;
    }
  }
}

.appInner {
  background-color: #f0f0f0;
  position: relative;
  z-index: 10;
  min-height: 80vh;
}

.flexRow {
  width: 100%;
  display: flex;
  align-items: center;
}

.page.completedCourses {
  @media (max-width: $screen-sm-max) {
    padding-top: 60px;
  }
  .mobile-list {
    margin-top: 10px;
    transition: margin-top .4s;
    &.isExpanded {
      margin-top: 200px;
    }
  }
}
.itemCount {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 12px;
  justify-content: flex-end;
  margin-top: -42px;
  color: rgba(0, 0, 0, 0.4);
  position: absolute;
  left: 75px;
  font-weight: 700;
  @media (max-width: $screen-sm-max) {
    display: none;
  }
  &.mobile {
    left: 0;
  }
  span {
    display: block;
    margin: 0 5px;
    color: $blue;
    font-size: 28px;
  }
}

.icon {
  display: inline-block;
  background-position: center;
  background-size: contain;
}

.icon.badge {
  background-image: url('../img/i-badge.svg');
  display: inline-block;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  min-width: 0;
}

.icon.check-black {
  background-image: url('../img/i-check-black.svg');
}
.icon.star {
  background-image: url(../img/star-white.svg);
}
.icon.search {
  background-image: url(../img/search.svg);
}
.icon.google {
  background-image: url('../img/i-google.svg');
}
.icon.back {
  background-image: url('../img/back.svg');
}
.icon.rocket {
  background-image: url('../img/rocket.svg');
}
.icon.pd-credits {
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  background-image: url('../img/pd-coin.svg');
}
.icon.pin {
  background-image: url('../img/pin.svg');
  &.black {
    background-image: url('../img/pin-black.svg');
  }
}

.icon.learningPath {
  background-image: url('../img/learning-path.svg');
  &.white {
    background-image: url('../img/learning-path-white.svg');
    background-color: rgba(0,0,0,0) !important;
  }
}

.button {
  &.large {
    text-transform: capitalize;
    font-size: 16px;
    font-weight: normal;
    min-width: 175px;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.14);
  }
  &.small {
    font-size: 12px;
    padding: 6px;
    min-width: 0;
    font-weight: 700;
    color: $blue;
    &:focus {
      background: #f0f0f0;
    }
  }
  &.primary {
    background-color: $blue;
    color: white;
    &:hover {
      background-color: darken($blue, 10%);
    }
  }
  &.secondary {
    background-color: white;
    color: $blue;
    &.Mui-disabled {
      color: $blue;
      background-color: rgba(0,0,0,0);
      box-shadow: none;
    }
    &:hover {
      background-color: #e3e3e3;
    }
  }
  .icon {
    width: 20px;
    height: 20px;
    display: block;
    background-repeat: no-repeat;
    margin-right: 10px;
  }
}
.category-icon {
  background-size: 112%;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: white;
  &.more.google {
    background-image: url('../img/i-google.svg');
  }
  &.meet {        
    background-image: url('../img/i-meet.svg');
  }
  &.mobilemind {
    background-image: url('../img/i-other.png');
    background-size: 80%;
  }
  &.docs {
    background-image: url('../img/i-docs.svg');
  }
  &.drive {
    background-image: url('../img/i-drive.svg');
  }
  &.earth {
    background-image: url('../img/i-earth.svg');
  }
  &.forms {
    background-image: url('../img/i-forms.svg');
  }
  &.slides {
    background-image: url('../img/i-slides.svg');
  }
  &.youtube {
    background-image: url('../img/i-youtube.svg');
  }
  &.classroom {
    background-image: url('../img/i-classroom.svg');
  }
  &.calendar {
    background-image: url('../img/i-calendar.svg');
  }
  &.chrome.extensions {
    background-image: url('../img/i-chrome-extensions.svg');
  }
  &.certification {
    background-image: url('../img/i-certification-info.svg');
  }
  &.sheets {
    background-image: url('../img/i-sheets.svg');
  }
}

.learn {
  max-width: 1180px;
  .learningPathInfo {
    background-color: white;
    margin: 20px 0;
    padding: 5px 15px;
    border-radius: 6px;
    max-width: 1060px;
    p {
      @media (max-width: $screen-sm-max) {
        line-height: 16px
      }
  
    }

    h2 {
      .icon {
        background-image: url('../img/learning-path.svg');
        width: 20px;
        height: 20px;
        margin-right: 10px;
        position: relative;
        top: 3px;
        @media (max-width: $screen-sm-max) {
          width: 15px;
          height: 15px;
          margin-right: 5px;
          top: 1px;
        }
      }
      margin: 15px 0;
      @media (max-width: $screen-sm-max) {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
  @media (max-width: $screen-sm-max) {
    padding-top: 55px;
    .badge-library {
      @media (max-width: $screen-sm-max) {
        margin-top: 15px;
      }
      &.isExpanded {
        margin-top: 190px;
      }
      .badgeWrapper {
        padding: 5px 0px 5px 15px !important;
      }
    }
  }
}

.badge-library {
  @media (max-width: $screen-sm-max) {
    margin-top: 65px;
    .MuiGrid-container {
      max-width: 100%;
    }
  }
  transition: margin-top .4s;
  &.isExpanded {
    margin-top: 150px;
  }
}

.peeler {
  transform: rotate(135deg) scale(.55);
  top: -45px;
  left: -45px;
  position: absolute;
	width: 180px;
	height: 180px;
	margin: 0 auto;
  backface-visibility: hidden;
  .circle {
    position: absolute;
    width: 140px;
    height:  140px;
    margin: 20px;
    border-radius: 999px;
    background-size: contain;
    transform: rotate(-135deg);
  }
  .back, .front {
    position: absolute;
    width: 180px;
    left: 0px;
    overflow: hidden;
    bottom: 0;
    top: auto;
  }
  .front {
    height: 70px;
    animation: peelFront .65s forwards;
    .badgeImage {
      width: 100%;
      height: 100%;
    }
    .circle {
      margin-top: -90px;
      animation: peelFrontCircle .65s forwards;
      background-size: contain;
    }
  }
  .back {
    height: 95px;
    top: 110px;
    animation: peelBack .65s forwards;
    .circle {
      margin-top: -100px;
      animation: peelBackCircle .65s forwards;
      border: 5px solid black;
      background-color: $blue;
    }
  }
}

.badgeWrapper {
  min-width: 325px;

  @media (max-width: $screen-md-max) {
    margin-bottom: -10px !important;
  }
  @media (max-width: $screen-sm-max) {
    min-width: 100%;
    margin: 0px !important;
  }
  .caret {
    cursor: pointer;
    height: 20px;
    background-image: url('../img/i-caret.svg');
    background-size: 10px;
    background-position: center;
    background-repeat: no-repeat;
    transform: scaleY(1);
    transition: .4s;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px;
    opacity: 0;
  }
}

.badge-library {
  .MuiGrid-item {
    margin: 5px 0;
  }
}

.badgeItem {
  position: relative;
  background: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  transition: .2s;
  overflow: hidden;
  height: 85px;
  transform: scale(1);
  &.completed {
    background: #f0f0f0;
    box-shadow: none;
    .innerBottom {
      display: none;
    }
    @media (max-width: $screen-sm-max) {
      height: auto !important;
      .innerTop {
        padding: 0px;
      }
    }
    .icon.check {
      opacity: 0;
      animation: fadeInUp .5s forwards;
      width: 20px;
      height: 20px;
      background-image: url('../img/i-check.svg');
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      border-radius: 100%;
      position: absolute;
      top: 5px;
      left: 60px;
    }
  }
  &:hover {
    .caret {
      opacity: .75;
    }
  }
  &.active {
    background-color: white;
    z-index: 10;
    @media (max-width: $screen-md-max) {
      transform: scale(1);
    }
    .caret {
      z-index: 11;
      transform: scaleY(-1);
      bottom: 3px;
      opacity: .75;
    }
    .innerTop {
      z-index: 10;
      position: relative;      
    }
  }

  .innerTop {
    cursor: pointer;
    padding: 10px 15px 35px 95px;
    display: flex;
    align-items: center;
    @media (max-width: $screen-md-max) {
      padding: 10px;
    }
    h2 {
      font-size: 18px;
      margin: 0 0 5px 0;
      @media (max-width: $screen-sm-max) {
        font-size: 14px;
        margin-bottom: 0px;
      }
    }
    .details {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .statusText {
      margin: 0;
      font-size: 12px;
      @media (max-width: $screen-sm-max) {
        margin-top: 3px;
        font-size: 10px;
      }
    }
    .badgeImage {
      margin-right: 10px;
      width: 80px;
      height: 80px;
      @media (max-width: $screen-sm-max) {
        width: 60px;
        height: 60px;
      }
    }
  }

  .innerBottom {
    border-top: 1px solid #f0f0f0;
    padding: 15px;
    position: relative;
    z-index: 10;
    background-color: white;
    height: 100%;
    header {
      text-transform: uppercase;
      color: #888888;
      font-size: 12px;
    }
    ul {
      margin-top: 10px;
    }
    li {
      a {
        transition: background-color .4s;
        padding: 0px 15px;
        margin: 0 -15px;
        display: flex;
        align-items: center;
        font-size: 14px;
        height: 45px; 
        color: black;
        &:hover {
          background-color: #f0f0f0;
        }
      }
      .category-icon {
        width: 20px;
        height: 20px;
        display: block;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.30);
        border-radius: 100%;
        margin-right: 10px;
      }
      .title {
        flex: 1;
        line-height: 16px;
        margin-right: 10px;
      }
      .icon {
        background-size: contain;
        background-repeat: no-repeat;
        width: 15px;
        height: 15px;
        &.in_progress {
          background-image: url('../img/laptop.svg');
        }
        &.submitted, &.grading_in_progress {
          padding: 0;
          background-image: url('../img/inbox.svg');
        }
        &.completed {
          background-image: url('../img/i-check.svg');
        }
      }
      .icon-wrapper {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;    
      }
    }
  }
}
.certificateInfo {
  button {
    .MuiButton-label {
      font-size: 10px;
      color: black; 
    }
    margin-left: -5px;
    &:hover, &:focus {
      background: #f0f0f0;
    }
  }
  .icon.certificate {
    background-image: url('../img/certificate.svg');
    width: 12px;
    height: 12px;
    margin-right: 5px;
  }
}

.statusBarWrapper {
  margin-top: 2px;
  height: 6px;
  background-color: #f0f0f0;
  border-radius: 5px;
  width: 100%;
  overflow: hidden;
  .statusBar {
    .statusBarInner {
      height: 6px;
      background-color: $green;
      animation: grow forwards 1.5s;
    }
  }
}

.mobileFilters {
  position: fixed;
  z-index: 20;
  transition: left .4s;
  background-color: #f0f0f0;
  left: 50px;
  top: 55px;
  right: 0;
  .filters {
    margin-left: 0;
    margin-top: 30px;
    min-width: 130px;
    width: auto;
    right: 0;
    height: 70px;
    overflow: hidden;
    padding: 10px;

    .toggle {
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      position: absolute;
      bottom: 15px;
      left: 0;
      right: 0;
      height: 15px;
      display: flex;
      justify-content: center;
      .icon {
        border-radius: 100%;
        width: 20px;
        height: 20px;
        display: block;
        margin: 0 auto;
        position: relative;
        top: 4px;
        background-color: $blue;
        background-position: center top 55%;
        background-image: url('../img/caret-white.svg');
        background-size: 6px;
        background-repeat: no-repeat;
        transform: scaleY(1) rotate(90deg);
      }
    }
    &.collapsed {
      opacity: 1;
      left: 0;
    }
    &.badgesEarned {
      .MuiFormControl-root {
        margin-top: 30px;
      }
    }
    &.expanded {
      .toggle {
        transform: scaleY(-1);
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        border-bottom: 0px;        
        .icon {
          top: -10px;
        }
      }
      &.explore {
        height: 305px;
      }
      &.badges {
        height: 240px;
      }
      &.coursesComplete {
        height: 300px;
      }
      &.badgesEarned {
        height: 155px;
      }
    }
    .MuiFormControlLabel-root {
      margin-top: 10px;
    }
    header {
      margin-bottom: -10px;
      height: 40px;
      min-width: 200px;
      padding: 0;
      button {
        margin-right: 0;
      }
    }
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  iframe, video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.learning-path-library {
  @media (max-width: $screen-sm-max) {
    .MuiGrid-root {
      margin: 0;
      width: 100%    
    }
  }
  .itemCount {
    margin-top: 0;
    position: relative;
    left: 0;
    font-weight: 700;
    margin-bottom: 20px;
    justify-content: flex-start;
    span {
      margin-left: 0px;
    }
  }
  .widget {
    transition: .3s;
    position: relative;
    top: 0;

    .timestamp {
      font-weight: 700;
      font-size: 12px;
      color: #555;
      position: relative;
      right: 0;
      left: 0;
      top: 0;
      opacity: 1;
      .icon {
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        margin-right: 5px;
        &.check {
          background-image: url('../img/i-check.svg');
        }
        &.laptop {
          background-image: url('../img/laptop.svg');
        }
      }
    }
    &.inProgress {
      .bottomContent {
        border-top: 5px solid $blue;
      }
    }
    &.completed {
      .pathImage {
        &:after {
          content: '';
          display: block;
          position: absolute;
          background-color: $green;
          bottom: 0;
          left: 0;
          right: 0;
          opacity: .8;
          top: 0;
        }
      }
      .bottomContent {
        border-top: 5px solid $green;
      }
      &:hover {
        top: 0px;
        box-shadow: 0px 2px 8px 0 rgba(111, 89, 89, 0.34);
      }
    }
    &:hover {
      top: -5px;
      box-shadow: 0px 2px 8px 0 rgba(111, 89, 89, 0.34);
    }
  }
  .widgetLabel {
    background-color: black;
    color: white;
    display: inline-flex;
    align-items: center;
  }
  .topContent {
    height: 125px;
  }
  .bottomContent {
    padding: 0 20px;
    margin: 0 -20px;
    .categoryList {
      padding-bottom: 40px;
    }
    .pathStatus {
      font-size: 12px !important;
      text-transform: none !important;
      position: absolute;
      bottom: 20px;
      left: 20px;
      right: 20px;
      .statusBarWrapper {
        background-color: #f0f0f0;
      }
    }
  }
}

$boxHeight: 10px;
$boxWidth: 25px;
$duration: 6s;

.loadingWrapper {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 25%;
  left: 0;
  right: 0;
  align-items: center;
  @media (max-width: $screen-sm-max) {
    position: fixed;
    top: 0;
    bottom: 0;
    margin-top: -100px;
  }
  span {
    position: relative;
    display: flex;
    top: 60px;
    justify-content: center;
    margin-left: 25px;
    font-weight: 700;
  }
  .square {
    position: absolute;
    height: $boxWidth;
    width: $boxWidth;
    transform-origin: top right;
    animation-timing-function: linear;
    animation: squareLoop $duration infinite;
    border: 3px solid #f0f0f0;
    border-radius: 5px;
    box-sizing: border-box;
    &:nth-child(1) {
      background-color: $blue;
    }
    &:nth-child(2) {
      background-color: $green;
      animation-delay: -3*$duration/5;
    }
    &:nth-child(3) {
      background-color: $blue;
      animation-delay: -2*$duration/5;
    }
    &:nth-child(4) {
      background-color: $green;
      animation-delay: -1*$duration/5;
    }

  }
}

@keyframes squareLoop {
  10% { transform-origin:top right; animation-fill-mode:forwards; transform: rotate(270deg); height: $boxWidth; width: $boxWidth; }

  10.001% { transform-origin:bottom right; transform:translate($boxWidth, 0px) rotate(0deg); height: $boxWidth; width: $boxWidth; }
25% { transform-origin:bottom right; transform:translate($boxWidth, 0px) rotate(0deg); height: $boxWidth; width: $boxWidth; }
  35% { transform-origin:bottom right; transform:translate($boxWidth, 0px) rotate(270deg);  height: $boxWidth; width: $boxWidth; }
  
35.001% { transform-origin:bottom left; transform:translate($boxWidth, $boxWidth) rotate(0deg); height: $boxWidth; width: $boxWidth;}
50% { transform-origin:bottom left; transform:translate($boxWidth, $boxWidth) rotate(0deg); height: $boxWidth; width: $boxWidth;}
  60% { transform-origin:bottom left; transform:translate($boxWidth, $boxWidth) rotate(270deg); height: $boxWidth; width: $boxWidth;}
  
60.001% { transform-origin:top left; transform:translate(0px, $boxWidth) rotate(0deg); height: $boxWidth; width: $boxWidth;}
75% { transform-origin:top left; transform:translate(0px, $boxWidth) rotate(0deg); height: $boxWidth; width: $boxWidth;}
  85% { transform-origin:top left; transform:translate(0px, $boxWidth) rotate(270deg); height: $boxWidth; width: $boxWidth;}

85.001% { transform-origin:top right; transform: rotate(0deg); height: $boxWidth; width: $boxWidth;}
100% { transform-origin:top right; transform: rotate(0deg); height: $boxWidth; width: $boxWidth; }
}



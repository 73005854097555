@import 'variables';

.layout.in-progress {

  p.statusText {
    font-size: 14px;
    padding: 15px 0;
  }
  .button.refresh {
    height: 30px;
    display: flex;
    font-size: 12px;
    color: $blue;
    text-transform: uppercase;
    position: absolute;
    left: 75px;
    margin-top: -36px;
    &:focus {
      background: rgba(0,0,0,0);
    }
    .icon {
      background-image: url('../img/refresh.svg');
      width: 13px;
      height: 13px;
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: 5px;
      position: relative;
      top: -1px;
    }
  }
  .MuiGrid-item {
    &:hover {
      .timestamp-inner {
        font-weight: 600;
        top: -32px !important;
      }
      .actions {        
        align-items: center;
        bottom: 16px;
        pointer-events: all;
        opacity: 1;
        .unsave {
          .icon {
            transform: scaleX(-1);
            margin-right: 5px;
          }
        }
      }     
    }
  }
}

.course-grid {
  transition: margin-top .4s;
  margin-top: 15px;
  &.isExpanded {
    margin-top: 200px;
  }
  .MuiGrid-item {
    max-width: 270px;
    position: relative;
  }
  .actions {
    display: flex;
    position: absolute;
    left: 20px;
    right: 18px;
    bottom: 5px;
    pointer-events: none;
    opacity: 0;
    transition: .4s;
    justify-content: space-between;
    &.prompt {
      opacity: 1;
      bottom: 16px;
      flex-direction: column;      
      .flexRow {
        justify-content: space-between;
        button:last-child {
          color: red;
        }
      }
      p {
        padding: 0 6px;
        line-height: 16px;
        font-size: 12px;
      }
    }
    &.removable {
      justify-content: space-between;
    }
  }
}

button.remove {
  width: 35px;
  img {
    max-width: 14px;
  }
}

.course-library {
  .category-icon {
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.28);
    transition: .4s;
    width: 50px;
    height: 50px;
    display: block;
    border-radius: 100%;
  }

  .star {
    width: 15px;
    height: 15px;
    background-repeat: no-repeat;
    margin: 0px 1px;
    position: relative;
    top: -1px;
    &:last-child {
      margin-right: 0;
    }
  }
  .course-level {
    display: flex;
    align-items: center;
    .level {
      margin-right: 5px;
      display: inline-block;
    }
  }
}

@mixin collapsedCard {
  h2 {
    margin-top: 0px;
    transform: scale(.8);
    height: 54px;
  }
  .course-details {
    bottom: 0;
  }
  .course-label {
    padding-left: 20px;
  }
  .category-icon-wrapper {
    width: 20px;
    top: 13px;
    left: 10px;
    .category-icon {
      height: 20px;
      width: 20px;
    }
  }
  @media (max-width: $screen-sm-max) {
    .category-icon-wrapper {
      top: 20px;
    }
    .course-label {
      margin-top: -3px;
    }
  }

}

.card.small {
  animation: fadeInUp .3s forwards;
  position: relative;
  height: 250px;
  opacity: 0;
  width: 250px;
  top: 0;
  transition: all .3s !important;
  margin: 0;
  padding: 0;
  @media (min-width: 1025px) {
    &.notStarted:hover {
      @include collapsedCard
    }
  }
  .MuiCardContent-root {
    position: relative;
  }
  header {
    margin-bottom: 0;
  }
  .timestamp {
    overflow: hidden;
    background-color: white;
    position: absolute;
    text-align: left;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 12px;
    font-size: 12px;
    font-weight: 600;
    &.prompt {
      height: 95px;
      .timestamp-inner {
        opacity: 0;
      }
    }
    .timestamp-inner {
      position: relative;
      transition: .4s;
      top: 0;
    }
    p {
      margin-top: 10px;
      margin-bottom: 0;
    }
  }
  &.isCompleted {
    border: 0px;
    box-shadow: none;
    .timestamp {
      border-top: 4px solid $green !important;
    }
  }
  &.isCompleted, &.hasFeedback {
    @include collapsedCard;
    .timestamp {
      background-color: white;
      padding: 10px;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      top: 40%;
      font-size: 12px;
      align-items: center;
      font-weight: 600;
      .feedback {
        &:before {
          display: inline-block;
          content: '';
          background-image: url('../img/feedback.svg');
          width: 15px;
          height: 15px;
          margin-right: 5px;
          position: relative;
          top: 3px;
          background-size: contain;
        }
      }
      p {
        margin: 5px 0;
      }
      strong {
        display: inline-block;
        margin-top: 5px;
        font-weight: 600;
      }
      .flexRow {
        align-items: center;
      }
      .icon.check {
        background-image: url('../img/i-check.svg');
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
    }

  }
  &.hasFeedback {
    .timestamp {
      font-weight: 600;
      border-top: 4px solid $blue;
    }
  }

  .card-image {
    max-height: none;
    position: absolute;
  }

  h2 {
    margin-top: 90px;
    font-size: 18px;
    color: white;
    text-align: center;
    text-shadow: 0 1px 4px rgba(0,0,0,0.77);
    transition: .4s;
    line-height: 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: scale(1);
    @media (max-width: $screen-md-max) {
      font-size: 16px;
      line-height: 18px;
      margin-top: 80px;
    }
  }

  .course-label {
    padding-left: 0;
    transition: .4s;
    font-size: 10px;
    color: white;
    font-weight: normal;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .category-icon-wrapper {
    top: 60px;
    position: absolute;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    transition: .4s;
    @media (max-width: $screen-md-max) {
      transform: scale(.8);
      margin-top: -10px;
 
    }
  }
  .course-details {
    background-color: white;
    height: 150px;
    position: absolute;
    top: auto;
    bottom: -150px;
    left: 0;
    right: 0;
    transition: .4s;
    padding: 15px;
    p {
      margin: 0 0 10px;
      font-size: 14px;
      line-height: 18px;  
    }
    footer {
      display: flex;
      position: absolute;
      right: 10px;
      bottom: 10px;
      .icon.badge {
        width: 20px;
        height: 20px;
        margin-left: 5px;
      }
      .category-icon-wrapper.secondary {
        width: 20px;
        top: 0px;
        left: 0px;
        position: relative;    
      }
    }
  }
}

.card-image {
  background-size: cover;
  background-position: center center;
  transition: height .4s;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &[data-bg="chrome + extensions"] {
      background-color: #44A1BE;
    }
    &[data-bg="more google"] {
      background-color: #B8B8B8;
    }
    &[data-bg="mobilemind"] {
      background-color: #68D4AB;
    }
    &[data-bg="docs"] {
      background-color: #4486F4;
    }
    &[data-bg="classroom"] {
      background-color: #38761D;
    }
    &[data-bg="calendar"] {
      background-color: #2E5ECC;
    }
    &[data-bg="slides"] {
      background-color: #FFB942;
    }
    &[data-bg="drive"] {
      background-color: #555;
    }
    &[data-bg="youtube"] {
      background-color: #B00002;
    }
    &[data-bg="earth + beyond"] {
      background-color: #669FF7;
    }
    &[data-bg="certification info"] {
      background-color: #2f3b7e;
    }
    &[data-bg="sheets"] {
      background-color: #109D58;
    }
    &[data-bg="forms"] {
      background-color: #7248BC;
    }  
}
.course-list {
  &.mobile-list {
    margin-top: 10px;
    transition: margin-top .4s;
    &.isExpanded {
      margin-top: 220px;
    }
  }
  a {
    color: black;
  }
}

.course-list-item {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
  background-color: white;
  position: relative;
  border-left: 0px solid #f0f0f0;
  transition: .4s;
  opacity: 0;
  animation: fadeInUp .3s forwards;
  .excludedText {
    padding: 10px 20px;
    margin: 20px -20px -20px;
    background-color: #f0f0f0;
  }
  &.isExcluded {
    header, h2, footer {
      opacity: .4;
    }
  }
  .button.unsave {
    @media (max-width: $screen-sm-max) {
      font-size: 10px;
      .icon {
        height: 15px;
        width: 15px;
      }
    }
    .icon {
      transform: scaleX(-1);
      margin-right: 5px !important;
    }
  }
  &:last-child {
    border-bottom: none;
  }
  &.completed {
    @media (max-width: $screen-sm-max) {
      border-bottom: 1px solid #f0f0f0 !important;
      padding: 10px 0 10px 10px;
      a {
        border-bottom: 0px !important;
      }
    }
    @media (min-width: $screen-md-max) {
      &:hover {
        border-left: 5px solid $green;
      }
    }
    .category-label {
      margin-left: 0px !important;
    }
  }
  .button.unsave {
    position: absolute;
    right: 10px;
    bottom: 12px;
    .icon {
      margin-right: 0;
    }
  }
  &.completed a {
    border-left: 0px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    align-items: center;
    padding: 20px 15px;
    .feedback {
      &:before {
        display: inline-block;
        content: '';
        background-image: url('../img/feedback.svg');
        width: 15px;
        height: 15px;
        margin-right: 5px;
        position: relative;
        top: 3px;
        background-size: contain;
      }
      margin-top: 10px;
    }
    @media (max-width: $screen-sm-max) {
      padding: 10px 0;
    }
    .course-label {
      padding-left: 0;
      @media (max-width: $screen-sm-max) {
        padding-left: 5px;
      }
    }
    h2 {
      margin-top: 5px;
      font-size: 22px;
      @media (max-width: $screen-sm-max) {
        font-size: 14px;
        margin-top: 4px;
      }
    }
    .commentsWrapper {
      header {
        margin-top: -5px;
        font-size: 12px;
        color: #555;
        text-transform: uppercase;
      }
      p {
        margin-top: 5px;
        font-style: italic;
        font-size: 16px;
        font-weight: lighter;
      }
    }
    .category-icon-wrapper {
      position: relative;
      margin-right: 15px;
      .icon.check {
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.28);
        border-radius: 100%;
        width: 20px;
        height: 20px;
        background-image: url('../img/i-check.svg');
        position: absolute;
        top: -6px;
        right: -6px;
      } 
      .category-icon {
        width: 35px;
        height: 35px;
      }  
    }
  }

  footer.prompt {
    padding-left: 15px;
    background-color: #f0f0f0;
    display: flex;
    z-index: 15;
    justify-content: center;
    align-items: center;
    height: 0;
    transition: .4s;
    overflow: hidden;
    .flexRow {
      flex: 0;
    }
    p {
      flex: 1;
      padding: 5px;
      margin-top: 0px;
    }
    button {
      margin: 0px 10px;
      &:last-child {
        color: red;
      }
    }
    &.active {
      border-top: 1px solid #f0f0f0;
      height: 50px;
    }
  }
  &:hover {
    @media (min-width: $screen-md-max) {
      border-left: 5px solid $blue;
      button.remove {
        opacity: 1;
        margin-top: -45px;
      }
    }
  }
  button.remove {
    position: absolute;
    z-index: 10;
    right: 100px;
    margin-top: -35px;
    opacity: 0;
    transition: .4s;
    @media (max-width: $screen-md-max) {
      display: none;
    }
  }
  &.isCompleted {
    border-left: 5px solid $green;
    &:hover {
      border-left: 5px solid $green;
    }  
  }
  &.in_progress {
    .timestamp.in-progress {
      font-weight: 600;
      position: absolute;
    }
  }
  a {
    position: relative;
    color: black;    
    padding: 20px;
    display: block;
    @media (max-width: $screen-md-max) {
      padding: 15px 20px;
    }
    @media (max-width: $screen-sm-max) {
      padding: 10px;
    }
  }

  p.feedback {
    border-top: 1px solid #f0f0f0;
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: -21px;
    position: relative;
    @media (max-width: $screen-md-max) {
      padding: 10px 20px;
      margin-bottom: -12px;
    }
  }
  .resume {
    position: absolute;
    right: 10px;
    margin-top: -45px;
    @media (max-width: $screen-md-max) {
      display: none;
    }
  }
  header {
    display: flex;
    align-items: center;
  }
  h2 {
    margin-top: 10px;
    @media (max-width: $screen-sm-max) {
      font-size: 16px;
    }
  }
  p {
    margin-bottom: 0px;
    @media (max-width: $screen-md-max) {
      margin-top: 8px;
    }
    @media (max-width: $screen-sm-max) {
      font-size: 10px;
      line-height: 12px;
    }
  }
  .course-details {
    margin-right: 80px;
    @media (min-width: 1025px) {
      margin-right: 140px;
    }
    @media (max-width: $screen-sm-max) {
      margin-right: 0;
    }
  }
  .timestamp {
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 12px;
    color: #555;
    font-weight: 600;
    @media (max-width: $screen-sm-max) {
      font-size: 8px;
      right: 10px;
      top: 10px;
    }
  }
  .course-label {
    text-transform: uppercase;
    font-size: 12px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    @media (max-width: $screen-sm-max) {
      font-size: 10px;
      .category-label {
        margin-right: 5px !important;
      }
      .icon.badge {
        margin-left: 5px;
        width: 15px;
        height: 15px;
      }
    }
    .category-label {
      margin-left: -5px;
      margin-right: 15px;
    }
    .star {
      width: 15px;
      height: 15px;
      position: relative;
      top: -2px;
      background-image: url('../img/star.svg');
      @media (max-width: $screen-sm-max) {
        width: 10px;
        height: 10px;
        top: -1px;
      }
    }
    .badge {
      margin-left: 10px;
      margin-top: -1px;
      width: 20px;
      height: 20px;
    }
  }
  .category-icon-wrapper {
    &.secondary {
      margin-left: 5px;
    }
    .category-icon {
      height: 20px;
      width: 20px;
      @media (max-width: $screen-sm-max) {
        height: 15px;
        width: 15px;
      }
    }
  }
}
@import 'variables';

.page.dashboard {
  @media (max-width: $screen-sm-max) {
    padding: 0 10px;
    margin-top: -50px;
  }
}

@media (max-width: $screen-sm-max) {
  .widgetWrapper {
    width: 100%;
  }
}

.widget {
  padding: 15px 20px 20px;
  margin: 10px;
  background-color: white;
  border-radius: 4px;
  opacity: 0;
  animation: fadeInUp .3s forwards;
  width: 350px;
  @media (max-width: 1440px) {
    padding: 12px;
    margin: 8px;
    width: 285px;
  }
  .timestamp {
    position: absolute;
    right: 20px;
    left: 20px;
    top: 18px;
    font-size: 12px;
    text-align: right;
    opacity: .5;
    font-weight: 700;
  }
  @media (max-width: $screen-sm-max) {
    width: 100%;
    margin: 15px 0;
    padding: 10px;
  }
  .widgetIcon {
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
    top: 20px;
    right: 15px;
    background-repeat: no-repeat;
    &.laptop {
      width: 18px;
      background-image: url('../img/laptop.svg');
    }
    &.pin {
      background-image: url('../img/pin.svg');
    }
  }
  h2 {
    margin-top: 10px;
    font-size: 18px;
    font-weight: 700;
  }

  p {
    font-size: 14px;
    line-height: 18px;
  }
  footer {
    display: flex;
    justify-content: flex-end;
    .MuiButton-label {
      font-size: 10px;
      @media (max-width: $screen-sm-max) {
        font-size: 9px !important;
      }
    }
  }

  ul {
    &.course-list-widget {
      margin: 0 -20px;
      padding: 0 20px;
    }
    li {
      a {
        transition: background-color .4s;
        padding: 10px 20px;
        margin: 0 -20px;
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 16px;
        color: black;
        &:hover {
          background-color: #f0f0f0;
        }
      }
    }
  }

  .category-icon-wrapper {
    margin-right: 10px;
    width: 20px;
    flex-shrink: 0;
    height: 20px;
    border-radius: 100%;
    overflow: hidden;
    background-size: contain;
    border-radius: 100%;
    box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.34);
    position: relative;
    top: -1px;
    .category-icon {
      width: 20px;
      height: 20px;
      display: block;
    }
  }

  .flexRow {
    margin: 10px 0;
  }
  .profilePicture {
    border-radius: 100%;
    width: 100px;
    height: 100px;
    box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.34);
    background-size: cover;
    @media (max-width: $screen-sm-max) {
      width: 75px;
      height: 75px;  
    }
  }
}

.widgetLabel {
  background-color: $blue;
  color: white;
  font-weight: 700;
  padding: 3px 6px;
  border-radius: 4px;
  text-transform: uppercase;
  display: inline-block;
  font-size: 10px;
  line-height: 14px;
  position: relative !important;
  z-index: 2;
  &.important {
    background-color: #FD4755;
  }
  &.success {
    color: black;
    display: inline-block;
    background-color: $green;
  }
}

.widget.badgeEarned {
  .imageWrapper {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    position: relative;
    margin-right: 10px;
    background-color: #fff;
  }
  .badgeImage {
    border-radius: 100%;
    overflow: hidden;
    width: 100px;
    height: 100px;
  }
  .icon.check {
    position: absolute;
    border-radius: 100%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    right: 2px;
    top: 2px;
    width: 30px;
    height: 30px;
    background-image: url('../img/i-check.svg');
  }
  .badgeInfo {
    align-items: flex-start;
    display: flex;
    justify-content: center;
    flex-direction: column;
    button {
      margin-top: 5px;
    }
  }
}

.widget.learningPath {
  a {
    color: black;
  }
  .pathStatus {
    font-size: 10px;
    text-transform: uppercase;
  }
  .statusBarWrapper {
    background-color: white;
  }
  .topContent {
    position: relative;
    padding-bottom: 15px;
  }
  .bottomContent {
    ul.categoryList {
      margin: 15px 0 0 0;
      display: flex;
      flex-wrap: wrap;
      .category-icon-wrapper {
        margin: 0 5px 5px 0;
      }
    }
  }
  p {
    margin: 15px 0;
  }
  .pathImage {
    position: absolute;
    top: -20px;
    right: -20px;
    left: -20px;
    bottom: 0px;
    background-size: cover;
    background-position: center;
    z-index: -1;
  }
  h2 {
    margin: 10px 0 10px;
  }
}

.widget.social {
  .widgetLabel {
    background-color: #555;
  }
  .logo.circle {
    background-image: url('../img/logo-circle.png');
    width: 100px;
    height: 100px;
    background-size: contain;
    margin-right: 20px;
  }
  .links {
    display: flex;
    flex-direction: column;
  }
  .button {
    .icon {
      width: 20px;
      height: 20px;
      position: relative;
      top: -1px;
      border-radius: 4px;
      overflow: hidden;
      &.twitter {
        background-image: url('../img/twitter.svg');
      }
      &.linkedIn {
        background-image: url('../img/linkedin.svg');
      }
      &.facebook {
        background-image: url('../img/facebook.svg');
      }
    }
  }
}

.widget.achievements {
  @media (max-width: 1280px) {
    .profilePicture {
      width: 75px;
      height: 75px;
    }
  }

  .stats {
    margin: 0 0 0 15px;
    font-size: 12px;
    flex: 1;
    h2 {
      margin-bottom: 10px;
    }
    .flexRow {
      align-items: center;
      margin: -5px 0 0;
    }
    @media (max-width: $screen-sm-max) {
      span {
        font-size: 24px;
      }
      font-size: 12px;
    }
    span {
      flex: 1;
      text-align: right;
      color: $blue;
      font-weight: 700;
      font-size: 24px;
      display: block;
      margin: 0 0 0 5px;
    }
  }
}

.widget.feedback {
  a {
    color: black;
  }
  .category-icon-wrapper {
    width: 50px;
    height: 50px;
    flex-shrink: 0;
  }
  .widgetIcon {
    background-image: url('../img/feedback.svg');
  }
  .flexRow {
    align-items: center;
  }
  .content {
    margin: 0;
    em {
      font-size: 14px;
      &:before {
        content: '';
        display: inline-block;
        height: 15px;
        width: 15px;
        position: relative;
        top: 2px; 
        margin-right: 5px;
        background-image: url('../img/feedback.svg');
        background-size: cover;
      }
    }
  }

  .category-icon {
    width: 50px;
    height: 50px;;
  }
}
.widget.announcement {
  .videoWrapper {
    margin: 15px -20px 0;
  }
  .icon.tip {
    width: 100px;
    height: 100px;
    margin-right: 10px;
    background-size: contain;
    background-image: url('../img/tip.svg');
    background-position: center;
    background-repeat: no-repeat;
  }
  .announcementImage {
    margin: 10px -20px 0;
    img {
      max-width: 100%;
      display: block;  
    }    
  }
  .card-image {
    position: relative;
    padding: 10px 20px;
    margin: 15px -20px;
    .icon.star {
      width: 10px;
      height: 10px;
      margin-right: 3px;
    }
    header {
      margin-top: 10px;
      display: flex;
      align-items: center;
    }
    .course-label {
      font-size: 10px;
      color: white;
      display: flex;
      font-weight: normal;
      text-transform: uppercase;  
      justify-content: space-between;
      flex: 1;
    }
    h2 {
      font-weight: 400;
      font-size: 18px;
      color: white;
      text-align: center;
      margin: 20px 0;
      text-shadow: 0 1px 4px rgba(0, 0, 0, 0.77);
      line-height: 22px;
    }
  }

}

.widget.support {
  .widgetLabel {
    background-color: #555;
  }
  .icon {
    &.book {
      background-image: url('../img/book.svg');
    }
    &.ticket {
      background-image: url('../img/ticket.svg');
    }
    &.support {
      width: 100px;
      height: 100px;
      margin-right: 10px;
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('../img/support.svg');
    }
  }
  .button {
    .MuiButton-label {
      justify-content: flex-start;
      display: flex;
      text-align: left;
      line-height: 14px;  
    }
    .icon {
      position: relative;
      top: -2px;
    }
  }
  .links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}
